import React, { FC, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Alert } from "shared/Alert/Alert";
import Config from "config.json";

export interface PageForgotPasswordProps {
  className?: string;
}

const PageForgotPassword: FC<PageForgotPasswordProps> = (props) => {
  const form = useRef(null);
  const [className, setClassName]: any = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event: any) => {
    // @ts-ignore: Object is possibly 'null'.
    event.preventDefault();
    // Reset error and success messages
    setErrorMessage('');
    setSuccessMessage('');
    setIsLoading(true)
    fetch(Config.SERVER_URL + '/reset-password', {
      method: 'POST',
      body: JSON.stringify({ 'email': event.target.email.value }),
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      if (res.status === 201) {
        setSuccessMessage('Check your email for a lin<>k to reset your password.');
      } else {
        setErrorMessage('Invalid email');
        // @ts-ignore: Object is possibly 'null'.
        form.current.reset();
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <div className={`nc-PageForgotPassword ${props.className}`} data-nc-id="PageForgotPassword">
      <Helmet>
        <title>Reset Password || Yoavio</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Recuperar contraseña
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <form ref={form} className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            {errorMessage && <Alert type="error">{errorMessage}</Alert>}
            {successMessage && <Alert type="success">{successMessage}</Alert>}
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Correo electrónico
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                name="email"
                required
                disabled={isLoading}
              />
            </label>
            <ButtonPrimary disabled={isLoading} type="submit">Continuar</ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageForgotPassword;
