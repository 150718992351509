import { Tab } from "@headlessui/react";
import { PencilAltIcon } from "@heroicons/react/outline";
import React, { Fragment, useEffect, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Alert from "shared/Alert/Alert";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcModal from "shared/NcModal/NcModal";
import { useParams, useNavigate } from "react-router-dom";
import LoginForm from "components/LoginForm/LoginForm";
import CreateAccountForm from "components/CreateAccountForm/CreateAccountForm";
import BookingDetails from "components/BookingDetails/BookingDetails";
import PassengerForm from "components/PassengerForm/PassengerForm";
import Loading from "shared/Loading/Loading";
import CommonLayout from "./CommonLayout";
import { Elements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Avatar from "shared/Avatar/Avatar";
import * as AuthService from "services/AuthService";
import Config from "config.json";
import * as CookieService from "services/CookieService";


export interface CheckOutPageProps {
  className?: string;
}

const stripePromise = loadStripe(Config.STRIPE_PUBLISHABLE_KEY);

const Passenger = ( props ) => {
  const { passenger, onSubmit } = props;
  const [ edited, setEdited ] = useState(false);
  const renderPassengerForm = () => {
    return (
      <PassengerForm
        passenger={passenger}
        onSubmit={(passenger) => {
          // We set edit to true to hide our modal, we could not find other method
          setEdited(true);
          onSubmit(passenger);
          // Reset edited to false since edition process is done, modal will be able to be opened again
          setTimeout(() => {
            setEdited(false);
          }, 0);
        }}
      />
    );
  }

  return (
    <div className="passenger">
      <div className="border border-neutral-200 dark:border-neutral-700 rounded-2xl flex flex-col sm:flex-row">
        <div className="flex-1 flex justify-between space-x-5 p-5">
          <div className="flex flex-row space-x-5">
            <Avatar sizeClass="w-10 h-10 sm:w-11 sm:h-11" />
            <div className="flex flex-col space-y-1">
              <span className="font-semibold">{passenger.firstName} {passenger.lastName}</span>
              <span className="text-sm text-neutral-400">Birth date {passenger.birthDate}</span>
            </div>
          </div>
          <NcModal
            modalTitle="Edit passenger"
            renderTrigger={(openModal) => (
              <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000 cursor-pointer" onClick={() => openModal()}/>
            )}
            renderContent={renderPassengerForm}
            isOpenProp={ edited ? false : undefined }
          />
        </div>
      </div>
    </div>
  );
}

const PaymentForm = (props) => {
  const { onSubmit } = props;
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  // Handle real-time validation errors from the CardElement.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  }

  return (
    <form onSubmit={onSubmit} className="stripe-form">
      <div className="space-y-5">
        <div className="space-y-1">
          <Label>Name </Label>
          <Input required placeholder="John Doe" id="name" name="name" value={name} onChange={(event) => { setName(event.target.value)}}/>
        </div>
        <div className="space-y-1">
          <Label>Email address </Label>
          <Input required placeholder="jenny.rosen@example.com" type="email" id="email" name="email" value={email} onChange={(event) => { setEmail(event.target.value)}}/>
        </div>
        <div className="space-y-1">
          <Label>Credit or debit card</Label>
          <div className="block w-full border border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3">
            <CardElement id="card-element" onChange={handleChange}/>
          </div>
        </div>
        <div className="pt-4">
          <ButtonPrimary>Confirm and pay</ButtonPrimary>
        </div>
      </div>
    </form>
  );
};


const CheckOutPage = (props) => {
  const navigate = useNavigate();
  const { flightId, numberOfPassengers } = useParams();
  const [flightData, setFlightData] = React.useState<any>({});
  const [user, setUser] = React.useState<any>(null);
  const [passengers, setPassengers] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const paymentRef = React.useRef<HTMLDivElement>(null);
  const accountRef = React.useRef<HTMLDivElement>(null);

  const confirmAndPay = (event) => {
    event.preventDefault();
    // @TODO Get payment token and related payment details from the form
    // Create a new booking
    const data = {
      'flight': flightId,
      'numberOfSeats': numberOfPassengers,
      'passengers': passengers,
    };
    fetch(Config.SERVER_URL + "/api/booking/", {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRFTOKEN": CookieService.get("csrftoken")
      },
      body: JSON.stringify(data)
    }).then((result) => {
      return result.json();
    }).then((booking) => {
      console.log(booking);
      // Go to confirmation page with the booking id
      navigate(`/confirmation/${booking.id}`);
    });
  }

  const createPassenger = (passenger: any) => {
    // Add to passengers array a new passenger
    // Generate unique id
    setPassengers([...passengers, passenger]);
  }

  const updatePassenger = (passenger: any) => {
    console.log(passenger)
    // Update passenger in passengers array
    setPassengers(passengers.map(p => p.id === passenger.id ? passenger : p));
  }

  useEffect(() => {
    // Test data
    // setPassengers([{ firstName: "John Eustakio", lastName: "Doe", birthDate: "2000-01-01", id: "1" }, { firstName: "Jane Maria", lastName: "Doe Martin", birthDate: "2000-01-01", id: "passenger" }]);
    setIsLoading(true);

    if (AuthService.isAuthenticated()) {
      setUser(AuthService.getUser());
    }

    // fetch profile data from API and store it in variable profileData
    fetch(Config.SERVER_URL + "/api/flight/" + flightId, {"credentials": "include"})
      .then(res => res.json())
      .then(flightData => {
        console.log(flightData);
        setFlightData(flightData);
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const renderPassengers = () => {
    return (
      <div>
        <h3 className="text-2xl font-semibold">Passengers</h3>
        <span className="font-semibold text-slate-400">{passengers.length}/{numberOfPassengers}</span>
        <div className="mt-6">
          <div className="w-14 border-b border-neutral-200 my-5"></div>
          { passengers.length > 0 ? <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
            {passengers.map((passenger, index) => {
              return <Passenger key={index} passenger={passenger} onSubmit={updatePassenger}/>;
            })}
          </div> : null }
          { numberOfPassengers && passengers.length < parseInt(numberOfPassengers) ?
            <div className="mt-6">
              <Alert type="info">You need to provide personal information about {numberOfPassengers && (parseInt(numberOfPassengers) - passengers.length)} passengers</Alert>
              <div className="mt-6">
                <PassengerForm onSubmit={createPassenger}/>
              </div>
            </div>: null
          }
        </div>
      </div>
    );
  }

  const renderAccount = () => {
    return (
      <div ref={accountRef}>
        <h3 className="text-2xl font-semibold">Account</h3>
        <div className="mt-6">
          { !user ?
            <Tab.Group>
              <Tab.List className="flex">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full flex items-center justify-center focus:outline-none ${
                        selected
                          ? "bg-neutral-800 text-white"
                          : "text-neutral-6000 dark:text-neutral-400"
                      }`}
                    >
                      <span className="mr-2.5">I have already an account</span>
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 text-white"
                          : "text-neutral-6000 dark:text-neutral-400"
                      }`}
                    >
                      Create account
                    </button>
                  )}
                </Tab>
              </Tab.List>
              <div className="w-14 border-b border-neutral-200 my-5"></div>
              <Tab.Panels>
                <Tab.Panel>
                  <LoginForm setUser={setUser}/>
                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                  <CreateAccountForm setUser={setUser} onSubmit={() => {
                    // @ts-ignore: Object is possibly 'null'.
                    accountRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
                  } }/>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group> :
            <div>
              <div className="w-14 border-b border-neutral-200 my-5"></div>
              <div className="flex items-center space-x-4">
                <Avatar sizeClass="w-10 h-10 sm:w-11 sm:h-11" />
                <div className="space-y-1 font-medium dark:text-white">
                  <div>{user.username}</div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">Joined in August 2014</div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }

  const renderPayment = () => {
    return (
      <div ref={paymentRef}>
        <h3 className="text-2xl font-semibold">Pay with</h3>
        <div className="mt-6">
          <Tab.Group>
            <Tab.List className="flex">
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full flex items-center justify-center focus:outline-none ${
                      selected
                        ? "bg-neutral-800 text-white"
                        : "text-neutral-6000 dark:text-neutral-400"
                    }`}
                  >
                    <span className="mr-2.5">Credit card</span>
                    <img className="w-8" src={visaPng} alt="" />
                    <img className="w-8" src={mastercardPng} alt="" />
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                      selected
                        ? "bg-neutral-800 text-white"
                        : "text-neutral-6000 dark:text-neutral-400"
                    }`}
                  >
                    Paypal
                  </button>
                )}
              </Tab>
            </Tab.List>

            <div className="w-14 border-b border-neutral-200 my-5"></div>
            <Tab.Panels>
              <Tab.Panel>
                <Elements stripe={stripePromise}>
                  <PaymentForm onSubmit={confirmAndPay}/>
                </Elements>
              </Tab.Panel>
              <Tab.Panel className="space-y-5">
                <form onSubmit={confirmAndPay}>
                  <div className="space-y-1">
                    <Label>Email </Label>
                    <Input type="email" defaultValue="example@gmail.com" />
                  </div>
                  <div className="space-y-1">
                    <Label>Password </Label>
                    <Input type="password" defaultValue="***" />
                  </div>
                  <div className="pt-4">
                    <ButtonPrimary>Confirm and pay</ButtonPrimary>
                  </div>
                </form>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    isLoading ? <Loading /> :
    <CommonLayout className={`${props.className}`}>
      <h2 className="text-3xl lg:text-4xl font-semibold">
        Book your flight <span className="font-light color-grey">Tokyo Singapore</span>
      </h2>
      <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
      <h3 className="text-2xl font-semibold">Your trip</h3>
      <BookingDetails numberOfPassengers={numberOfPassengers} flightData={flightData} />
      {renderAccount()}
      {user && renderPassengers()}
      {numberOfPassengers && passengers.length == parseInt(numberOfPassengers) && renderPayment()}
    </CommonLayout>
  );
};

export default CheckOutPage;