import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/BecomeAnAuthorImg.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";

// @todo Refactor this component since it is nothing to do with the authoring process

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImgDemo,
}) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
        <Logo className="w-20" />
        <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
          ¿Por qué elegirnos?
        </h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
          Nuestra misión es ayudarte a que consigas el mejor precio para el vuelo
          que estás buscando. Nuestro proceso de reserva es muy sencillo y nuestro
          equipo de atención al cliente estará disponible hasta que llegues a tu
          destino.
        </span>
        <ButtonPrimary href="/flights" className="mt-6 sm:mt-11">
          Buscar vuelos
        </ButtonPrimary>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionBecomeAnAuthor;
