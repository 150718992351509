import React from "react";
import FlightCard from "components/FlightCard/FlightCard";
import { PencilAltIcon } from "@heroicons/react/outline";

interface BookingDetailsProps {
  flightData: any;
  numberOfPassengers: number;
}

const BookingDetails = ({ flightData, numberOfPassengers }) => {
  return (
    <div>
      <FlightCard key={flightData.id} data={flightData} action="#" />
      <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-2xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
        <div className="flex-1 p-5 flex justify-between space-x-5">
          <div className="flex flex-col">
            <span className="text-sm text-neutral-400">Date</span>
            <span className="mt-1.5 text-lg font-semibold">
              Aug 12 - 16, 2021
            </span>
          </div>
        </div>
        <div className="flex-1 p-5 flex justify-between space-x-5">
          <div className="flex flex-col">
            <span className="text-sm text-neutral-400">Passengers</span>
            <span className="mt-1.5 text-lg font-semibold">{numberOfPassengers} passengers</span>
          </div>
          <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" />
        </div>
      </div>
    </div>
  );
}

export default BookingDetails;