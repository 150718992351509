/* @todo This should be in a common folder? */

import React from "react";
import { FC } from "react";

export interface CommonLayoutProps {
  children: React.ReactNode;
  className?: string;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children, className="" }) => {
  return (
    <div className={className}>
      <main className="container mb-24 lg:mb-32">
        <div className="lg:p-10 grid grid-cols-1 gap-10">
          <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 px-0 sm:p-6 xl:p-8 space-y-8">
            {children}
          </div>
        </div>
      </main>
    </div>
  );
};

export default CommonLayout;