import React, { useState } from 'react';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Config from "config.json";
import Alert from "shared/Alert/Alert";
import * as AuthService from "services/AuthService";

const CreateAccountForm = (props) => {
  const { setUser, onSubmit } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');

  const resetPassword = () => {
    setPassword('');
    setPassword2('');
  }

  const handleSubmit = (event: any) => {
    setIsLoading(true);
    setErrorMessage("");
    event.preventDefault();

    fetch(Config.SERVER_URL + '/register', {
      method: 'POST',
      body: JSON.stringify({
        'email': email,
        'username': username,
        'password': password,
        'password2': password2,
        'firstName': firstName,
        'lastName': lastName
      }),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    }).then(res => {
      if (res.status === 201) {
        AuthService.login({ 'username': email });
        onSubmit();
        setUser({ 'username': email });
      } else {
        res.json().then(data => {
          let firstError = data[Object.keys(data)[0]];
          // If first error is a list we will set first element again
          if (firstError instanceof Array) {
            firstError = firstError[0];
          }
          setErrorMessage(firstError);
          resetPassword();
        });
      }
    }).catch(err => {
      console.log(err);
      console.log('Error');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-5">
        {errorMessage && <Alert type="error">{errorMessage}</Alert>}
        <div className="space-y-1">
          <Label>Email address </Label>
          <Input required disabled={isLoading} placeholder="jenny.rosen@example.com" type="email" id="email" name="email" value={email} onChange={(event) => { setEmail(event.target.value) }}/>
        </div>
        <div className="space-y-1">
          <Label>Username </Label>
          <Input required disabled={isLoading} placeholder="jenny" id="username" name="username" value={username} onChange={(event) => { setUsername(event.target.value) }}/>
        </div>
        <div className="space-y-1">
          <Label>Password </Label>
          <Input required disabled={isLoading} type="password" id="password" name="passsword" value={password} onChange={(event) => { setPassword(event.target.value) }}/>
        </div>
        <div className="space-y-1">
          <Label>Confirm password </Label>
          <Input required disabled={isLoading} type="password" id="password2" name="passsword2" value={password2} onChange={(event) => { setPassword2(event.target.value) }}/>
        </div>
        <div className="space-y-1">
          <Label>First name </Label>
          <Input required disabled={isLoading} id="firstName" name="firstName" value={firstName} onChange={(event) => { setFirstName(event.target.value) }}/>
        </div>
        <div className="space-y-1">
          <Label>Last name </Label>
          <Input required disabled={isLoading} id="lastName" name="lastName" value={lastName} onChange={(event) => { setLastName(event.target.value) }}/>
        </div>
        <div className="pt-4">
          <ButtonPrimary disabled={isLoading}>Create account</ButtonPrimary>
        </div>
      </div>
    </form>
  );
}

export default CreateAccountForm;