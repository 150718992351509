import Config from "config.json";
import * as CookieService from './CookieService';

export const login = (user: any) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const isAuthenticated = () => {
  const user = localStorage.getItem("user");
  return user ? true : false;
};

export const getUser = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};

export const logout = () => {
  fetch(Config.SERVER_URL + "/logout", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "X-CSRFToken": CookieService.get("csrftoken")
    },
    credentials: "include"
  }).then(response => {
    if (response.status === 204) {
      const user = localStorage.removeItem("user");
      // Redirect to login page
      window.location.href = "/login";
    }
  })
};