import { useState } from 'react';
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Config from "config.json";
import Alert from "shared/Alert/Alert";
import * as AuthService from "services/AuthService";

const LoginForm = (props) => {
  const { setUser } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const resetForm = () => {
    setEmail("");
    setPassword("");
  }

  const handleSubmit = (event: any) => {
    setIsLoading(true);
    setErrorMessage("");
    event.preventDefault();

    fetch(Config.SERVER_URL + '/login', {
      method: 'POST',
      body: JSON.stringify({ 'username': email, 'password': password }),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    })
      .then(res => {
        if (res.status === 202) {
          AuthService.login({ 'username': email });
          setUser({ 'username': email });
        } else {
          setErrorMessage('Invalid username or password');
          resetForm();
        }
      })
      .catch(err => {
        console.log(err);
        setErrorMessage('Invalid username or password');
        resetForm();
      }).finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-5">
        {errorMessage && <Alert type="error">{errorMessage}</Alert>}
        <div className="space-y-1">
          <Label>Email address </Label>
          <Input required disabled={isLoading} placeholder="jenny.rosen@example.com" id="email" name="email" value={email} onChange={(event) => { setEmail(event.target.value) }}/>
        </div>
        <div className="space-y-1">
          <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
            Password
            <Link to="/forgot-password" className="text-sm">
              Forgot password?
            </Link>
          </span>
          <Input required disabled={isLoading} type="password" id="password" name="password" value={password} onChange={(event) => { setPassword(event.target.value) }}/>
        </div>
        <div className="pt-4">
          <ButtonPrimary disabled={isLoading}>Login</ButtonPrimary>
        </div>
      </div>
    </form>
  );
}

export default LoginForm;