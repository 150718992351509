import Label from "components/Label/Label";
import React, { FC } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import Config from "config.json";
import { Alert } from "shared/Alert/Alert";
import * as CookieService from "services/CookieService";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const [profileData, setProfileData] = React.useState<any>();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [successMessage, setSuccessMessage] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData(event.currentTarget);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    fetch(Config.SERVER_URL + "/profile", {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRFTOKEN": CookieService.get("csrftoken")
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then(data => {
        setSuccessMessage("Profile updated successfully");
        setErrorMessage(undefined);
      })
      .catch(error => {
        setErrorMessage('Something went wrong');
        setSuccessMessage(undefined);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  return (
    <div className={`n-c-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account || Yoavio</title>
      </Helmet>
      <CommonLayout setProfileData={setProfileData} >
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Account infomation</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <form onSubmit={handleSubmit}>
            <div className="max-w-xl space-y-6">
              {errorMessage && <Alert type="error">{errorMessage}</Alert>}
              {successMessage && <Alert type="success">{successMessage}</Alert>}
              {/* NAME */}
              <div>
                <Label>Name</Label>
                <Input disabled={isLoading} required name="firstName" className="mt-1.5" defaultValue={profileData && profileData.firstName} />
              </div>
              {/* ---- */}
              <div>
                <Label>Last name</Label>
                <Input disabled={isLoading} required name="lastName" className="mt-1.5" defaultValue={profileData && profileData.lastName} />
              </div>
              {/* ---- */}
              <div>
                <Label>Username</Label>
                <Input disabled={isLoading} required name="username" className="mt-1.5" defaultValue={profileData && profileData.username} />
              </div>
              {/* ---- */}
              <div>
                <Label>Email</Label>
                <Input disabled={isLoading} required name="email" type="email" className="mt-1.5" defaultValue={profileData && profileData.email} />
              </div>
              {/* ---- */}
              <div className="pt-2">
                <ButtonPrimary disabled={isLoading}>Update info</ButtonPrimary>
              </div>
            </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
