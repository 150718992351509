import React from "react";

const LogoSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="112.963"
      height="38.746"
      version="1.1"
      viewBox="0 0 149.441 51.257"
    >
      <defs>
        <linearGradient id="linearGradient62">
          <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
          <stop offset="1" stopColor="purple" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient
          x1="22.156"
          x2="173.404"
          y1="52.488"
          y2="52.488"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient62"
        ></linearGradient>
        <linearGradient
          id="linearGradient228"
          x1="22.156"
          x2="173.404"
          y1="52.488"
          y2="52.488"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient62"
        ></linearGradient>
      </defs>
      <g transform="translate(-22.156 -26.86)">
        <g
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient228)"
          fillOpacity="1"
          stroke="none"
          strokeWidth="0.265"
          ariaLabel="yoavio"
          fontFamily="sans-serif"
          fontSize="50.8"
          fontStyle="normal"
          fontWeight="normal"
        >
          <path
            d="M48.318 39.661q2.997 1.321 2.997 3.2 0 .915-.406 1.728-.407.813-.407.863l-13.31 29.77q-.406.964-.71 1.472-.254.508-.864.966-.61.457-1.524.457t-2.692-.762q-2.998-1.321-2.998-3.15 0-1.219 4.877-11.633L23.172 45.554q-1.016-1.676-1.016-2.49 0-1.625 2.743-3.25 1.727-1.016 2.693-1.016.965 0 1.574.406.61.406.864.864.305.406 2.794 4.724 2.54 4.267 4.826 7.976.254-.813 2.743-6.249 2.49-5.486 2.642-5.842.203-.355.61-.863.66-.864 1.828-.864 1.22 0 2.845.711z"
            letterSpacing="-4.366"
          ></path>
          <path
            d="M48.957 52.768q0-6.096 4.267-10.059 4.267-3.962 9.753-3.962 5.487 0 9.703 3.962 4.217 3.912 4.217 10.008 0 4.216-2.185 7.518-2.184 3.251-5.334 4.826-3.099 1.524-6.451 1.524-3.353 0-6.503-1.625-3.15-1.677-5.334-4.877-2.133-3.251-2.133-7.315zm10.617 3.657q1.625 1.22 3.302 1.22 1.676 0 3.353-1.27 1.676-1.27 1.676-3.76 0-2.489-1.575-3.708-1.575-1.22-3.403-1.22-1.83 0-3.404 1.27-1.575 1.27-1.575 3.76 0 2.438 1.626 3.708z"
            letterSpacing="-2.733"
          ></path>
          <path
            fillOpacity="1"
            strokeWidth="0.265"
            d="M94.82 42.201q.304-2.845 4.013-2.845 1.98 0 2.946.305.965.305 1.32 1.067.407.762.458 1.372.102.558.102 1.727v17.78q0 1.168-.102 1.778-.05.559-.406 1.32-.712 1.321-3.506 1.321-2.743 0-3.708-.66-.914-.711-1.067-2.032-1.88 2.692-6.3 2.692-4.368 0-8.127-4.013-3.76-4.013-3.76-9.296 0-5.334 3.76-9.297 3.81-4.013 8.331-4.013 1.727 0 3.15.61 1.422.61 1.98 1.168.56.508.915 1.016zm-9.246 10.567q0 1.676 1.32 3.048 1.321 1.32 3.2 1.32 1.88 0 3.1-1.371 1.27-1.372 1.27-2.997 0-1.677-1.22-3.1-1.168-1.422-3.2-1.422-1.981 0-3.251 1.423-1.22 1.422-1.22 3.099z"
            fontFamily="Fredoka One"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="-2.646"
          ></path>
          <path
            fillOpacity="1"
            strokeWidth="0.265"
            d="M131.243 39.966q2.641 1.575 2.641 3.353 0 1.016-1.016 2.794l-10.515 18.034q-.508.863-1.626 1.575-1.118.66-2.235.66-1.067 0-2.083-.508-.965-.559-1.372-1.118l-.457-.558-10.566-18.085q-1.016-1.677-1.016-2.743 0-1.83 2.641-3.404 1.93-1.118 3.048-1.118t1.728.712q.66.66 1.371 2.032l6.655 12.801 6.655-12.801q.559-.966.813-1.372.304-.457.914-.864.61-.457 1.626-.457t2.794 1.067z"
            fontFamily="Fredoka One"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="-2.646"
          ></path>
          <path
            fillOpacity="1"
            strokeWidth="0.265"
            d="M134.066 43.827q0-1.169.051-1.727.102-.61.457-1.372.711-1.32 3.963-1.32 2.438 0 3.454.863.864.711.914 2.184.051.508.051 1.473v17.78q0 1.169-.101 1.778-.051.56-.458 1.321-.66 1.321-3.911 1.321-3.2 0-3.912-1.372-.355-.762-.457-1.32-.05-.56-.05-1.778zm8.331-9.398q-.355.711-1.32 1.016-.966.305-2.591.305-1.575 0-2.54-.305-.965-.305-1.372-1.067-.355-.762-.457-1.32-.05-.61-.05-1.779 0-1.168.05-1.727.102-.61.457-1.32.711-1.372 3.963-1.372 3.2 0 3.86 1.371.407.762.458 1.372.101.559.101 1.727 0 1.168-.101 1.778-.051.559-.458 1.32z"
            fontFamily="Fredoka One"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="-2.646"
          ></path>
          <path
            fillOpacity="1"
            strokeWidth="0.265"
            d="M143.656 52.768q0-6.096 4.267-10.059 4.267-3.962 9.754-3.962 5.486 0 9.703 3.962 4.216 3.912 4.216 10.008 0 4.216-2.185 7.518-2.184 3.251-5.334 4.826-3.098 1.524-6.451 1.524-3.353 0-6.503-1.625-3.15-1.677-5.334-4.877-2.133-3.251-2.133-7.315zm10.617 3.657q1.626 1.22 3.302 1.22 1.677 0 3.353-1.27 1.676-1.27 1.676-3.76 0-2.489-1.575-3.708-1.574-1.22-3.403-1.22t-3.404 1.27q-1.575 1.27-1.575 3.76 0 2.438 1.626 3.708z"
            fontFamily="Fredoka One"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="-2.646"
          ></path>
        </g>
      </g>
    </svg>
  );
};
// <svg
//   xmlns="http://www.w3.org/2000/svg"
//   width="112.963"
//   height="38.744"
//   viewBox="0 0 149.44 51.257"
// >
//   <defs>
//     <linearGradient
//       id="linearGradient228"
//       x1="20.928"
//       x2="169.92"
//       y1="96.851"
//       y2="30.134"
//       gradientUnits="userSpaceOnUse"
//     >
//       <stop offset="0"></stop>
//       <stop offset="1" stopColor="#8080ff"></stop>
//     </linearGradient>
//   </defs>
//   <text
//     x="21.394"
//     y="66.128"
//     fill="url(#linearGradient228)"
//     strokeWidth="0.265"
//     fontFamily="sans-serif"
//     fontSize="50.8"
//     style={{ lineHeight: "1.25" }}
//     transform="translate(-22.156 -26.859)"
//   >
//     <tspan
//       x="21.394"
//       y="66.128"
//       fontFamily="'Fredoka One'"
//       letterSpacing="-2.646"
//     >
//       <tspan letterSpacing="-2.733">
//         <tspan letterSpacing="-4.366">y</tspan>o
//       </tspan>
//       avio
//     </tspan>
//   </text>
// </svg>
export default LogoSvg;