const HelpSection = ({}) => (
  <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
    <div>
      <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
        ☎ Llámanos
      </h3>
      <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
        +34 919334244 Horario continuo 9-18:00 de lunes a viernes
      </span>
    </div>
    <div>
      <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
        💌 Correo electrónico
      </h3>
      <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
        Escríbenos a ayuda@yoavio.com con tu problema
      </span>
    </div>
  </div>
);

export default HelpSection;