import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'
import { Helmet } from "react-helmet";

import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import rightImg from "images/hero-right-2.png";
import Heading from "components/Heading/Heading";
import HelpSection from "components/HelpSection/HelpSection";

const questions = [
  {
    "question": "Reembolso del vuelo cancelado",
    "answer": "Una vez la aerolínea haya autorizado el reembolso por cancelación, comenzamos a gestionarlo. Puedes comprobar el estado de tu reembolso en <b><a href=\"/my-trips\">Mis viajes</a></b>. El importe que te corresponda será reembolsado al mismo método de pago que utilizaste para hacer la reserva. En ocasiones solicitamos manualmente el reembolso a la aerolínea. El proceso puede durar algún tiempo (hasta un par de meses).",
  },
  {
    "question": "¿Cuánto equipaje puedo llevar?",
    "answer": "Por lo general las aerolíneas permiten llevar una pieza de equipaje de mano gratis. El importe total, el peso y las dimensiones máximas dependen de cada aerolínea. Las condiciones de equipaje cada vez son más estrictas y con algunas aerolíneas sólo está permitido llevar una pequeña pieza de equipaje de mano (artículo personal).",
  },
  {
    "question": "¿Cómo puedo cambiar mi billete?",
    "answer": "<p>Si quieres cambiar la fecha o la ruta o hacer cualquier otro tipo de modificación en tu billete, ponte en contacto con nosotros a través de nuestro correo electrónico y uno de nuestros agentes gestionará tu petición. En cualquier caso, ten en cuenta que los cambios de nombre no suelen estar permitidos.</p><p>Si tu vuelo sale en menos de 24 horas, te recomendamos que te pongas en contacto con la aerolínea directamente (por teléfono o como muy tarde en el aeropuerto).</p>"
  },
  {
    "question": "No he recibido mi billete",
    "answer": "¿No has recibido un email con tu(s) billete(s)? Revisa tu carpeta de ‘spam’. ¿Aún nada? Seguramente ocurrió algo al introducir tu dirección de email. Ponte en contacto con nosotros y te ayudaremos."
  },
  {
    "question": "¿Cómo funciona el checkin online?",
    "answer": "Antes de hacer el check-in online (o facturación en línea) te recomendamos que revises toda tu información personal y la del resto de pasajeros en la reserva. Ten a mano tus billetes y los documentos de viaje necesarios para el vuelo (pasaporte válido/documento de identidad, visados y otros documentos de viaje obligatorios). Una vez tengas todos los documentos necesarios dirígete al sitio web de la aerolinea y realiza el proceso."
  },
  {
    "question": "Información acerca del COVID-19",
    "answer": "En el caso de que algún vuelo de vuestra reserva sea cancelado por la aerolínea operadora del mismo, en nuestra calidad de intermediarios, contactaremos para informaros de ello tan pronto como recibamos la información de la aerolínea."
  },
  {
    "question": "Medidas contra el Coronavirus antes de volar",
    "answer": "Es extremadamente importante estar preparados para volar hoy en día, dado que los requisitos pueden cambiar en cualquier momento. Recomendamos encarecidamente que revises la página web de la aerolínea con la que vuelas y del aeropuerto de origen y destino, para estar al tanto de las últimas actualizaciones."
  },
  {
    "question": "¿Cuándo voy a recibir mi billete?",
    "answer": "Lo primero que recibes cuando reservas un billete es el email de confirmación. En las próximas 72 horas (o menos si la salida es en 3 días) recibirás tu billete electrónico en tu buzón de correo."
  },
  {
    "question": "¿Cuándo puedo realizar el check-in online?",
    "answer": "El check-in online normalmente se encuentra disponible entre 24 a 36 horas antes de la salida de tu vuelo. Para vuelos intercontinentales, el check-in suele estar disponible 48 horas antes de la salida. Desde aquí, le aconsejamos a todos los clientes que visiten la página web de la aerolínea para realizar el check-in online. Allí también podrás consultar las últimas actualizaciones sobre tu vuelo."
  },
  {
    "question": "¿Cuánto equipaje facturado puedo llevar?",
    "answer": "Que el equipaje facturado esté incluido en tu reserva dependerá de la aerolínea con la que vueles, la ruta (doméstico/Europeo/Intercontinental) y del tipo de tarifa de tu billete (light/basic/flex). Estos símbolos te indicarán si el equipaje está incluido o no en tu reserva cuando reserves un vuelo a través de nuestra página web."
  },
  {
    "question": "¿Cómo puedo cancelar mi reserva?",
    "answer": "Para cancelar tu reserva envíanos un correo electrónico con el código de la reserva y el motivo de la cancelación."
  },
  {
    "question": "Vuelo cancelado por la aerolínea",
    "answer": "Las aerolíneas suelen ofrecer una alternativa cuando el vuelo que has reservado ha sido cancelado. Te enviaremos un email con una nueva alternativa de vuelo y si no te conviene la opción que te proponemos, puedes cancelar tu vuelo. Te informaremos de las condiciones de la aerolínea y de tu billete, para que puedas elegir la opción que más te convenga."
  },
  {
    "question": "¿Qué debo hacer si mi reserva no se ha realizado correctamente?",
    "answer": "En algunos casos, es posible que una reserva no se realice correctamente. Esto puede deberse a un mal funcionamiento de la página web en ese momento o a que el vuelo que quiera reservar ya no esté disponible. Si la confirmación de tu reserva no aparece en la pantalla tras finalizar el proceso de reserva, ponte en contacto con nosotros a través de nuestro correo electrónico y uno de nuestros agentes gestionará tu petición."
  },
  {
    "question": "¿Puedo cambiar la fecha o ruta mi vuelo?",
    "answer": "Si es o no posible cambiar la fecha o la ruta de tu billete dependerá de las condiciones de tu billete. Cada billete tiene sus propias condiciones. Puedes ver las condiciones de tu billete al seleccionar tu vuelo durante la reserva. En la mayoría de casos, el cambio conlleva gastos adicionales."
  },
];

const HelpPage = () => {
  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-4 px-0 sm:p-6 xl:p-8">
        {questions.map((question, index) => {
          return (
            <Disclosure defaultOpen={false}>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>{question.question}</span>
                  <ChevronUpIcon
                    className={`${open ? 'rotate-180 transform' : ''
                      } h-5 w-5 text-purple-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pb-2 text-sm text-gray-500">
                  <div className="space-y-4" dangerouslySetInnerHTML={{__html: question.answer}} />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          );
        })}
      </div>
    );
  };

  return (
    <div className={`nc-HelpPage`} data-nc-id="HelpPage">
      <Helmet>
        <title>Yoavio || Ayuda</title>
      </Helmet>
      <main className="container mt-11 mb-24 lg:mb-32">
        <Heading
          desc="¿Tienes alguna duda? ¡No te preocupes!"
          isCenter={true}
        >
          🏝️ Ayuda
        </Heading>
        <div className="flex flex-col-reverse lg:flex-row">
          <div className="w-full lg:w-2/3 lg:pr-10">{renderMain()}</div>
          <div className="hidden lg:block lg:w-1/3">
            <HelpSection />
          </div>
        </div>
      </main>
    </div>
  );
};

export default HelpPage;