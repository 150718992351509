import React from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";

export interface AlertProps {
  type?: "default" | "warning" | "info" | "success" | "error";
  className?: string;
}

export const Alert: React.FC<AlertProps> = ({
  children = "Alert Text",
  type = "default",
  className = "",
}) => {
  let classes = '';
  switch (type) {
    case "default":
      classes = "bg-black-50 border-black-300 text-black-600";
      break;
    case "info":
      classes = "bg-blue-50 border-blue-300 text-blue-600";
      break;
    case "success":
      classes = "bg-green-50 border-green-300 text-green-600";
      break;
    case "error":
      classes = "bg-red-50 border-red-300 text-red-600";
      break;
    case "warning":
      classes = "bg-orange-50 border-orange-300 text-orange-600";
      break;
    default:
      break;
  }

  return (
    <div className={className}>
      <div className={`border px-4 py-3 rounded relative ${classes}`} role="alert">
        <span className="block sm:inline">{children}</span>
      </div>
    </div>
  );
};

export default Alert;