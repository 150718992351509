import React from 'react';
import ReactMarkdown from 'react-markdown';
import Heading from "components/Heading/Heading";
import HelpSection from "components/HelpSection/HelpSection";
import { Helmet } from "react-helmet";


export interface MarkdownPageProps {
  className?: string;
  filename: string;
  title: string;
  description: string;
}

const MarkdownPage = ({ className, filename, title, description }: MarkdownPageProps) => {
  const [markdown, setMarkdown] = React.useState('')
  React.useEffect(() => {
    const raw = require('data/markdown/' + filename + '.md');
    fetch(raw)
      .then(response => response.text())
      .then(text => setMarkdown(text));
  }, [filename])

  return (
    <div>
      <Helmet>
        <title>Yoavio || {title} {description}</title>
      </Helmet>
      <main className="container mt-11 mb-24 lg:mb-32">
        <Heading
          desc={description}
          isCenter={true}
        >
          🏝️ {title}
        </Heading>
        <div className="flex flex-col-reverse lg:flex-row">
          <div className="w-full lg:w-2/3 lg:pr-10">
            <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
              <div className="prose prose-sm prose-stone !max-w-screen-md sm:prose lg:prose-lg">
                <ReactMarkdown>{markdown}</ReactMarkdown>
              </div>
            </div>
          </div>
          <div className="hidden lg:block lg:w-1/3">
            <HelpSection />
          </div>
        </div>
      </main>
    </div>
  )
}

export default MarkdownPage;