import { useEffect } from 'react';
import * as AuthService from "services/AuthService";
import Loading from "shared/Loading/Loading";


const PageLogout = () => {
  // Logout user when this component is loaded
  useEffect(() => {
    AuthService.logout();
  }, []);
  return (
    <div className="container mb-24 lg:mb-32">
      <Loading />
    </div>
  );
}

export default PageLogout;