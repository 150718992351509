import React, { FC, useState } from "react";
import "react-dates/initialize";
import ExperiencesSearchForm from "./ExperiencesSearchForm";
import StaySearchForm from "./StaySearchForm";
import RentalCarSearchForm from "./RentalCarSearchForm";
import FlightSearchForm from "./FlightSearchForm";

export type SearchTab = "Vuelos" | "Hoteles" | "Alquiler de coche";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "Vuelos" | "Hoteles" | "Alquiler de coche";
  handleSubmit?: () => void;
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "Vuelos",
  currentPage,
  handleSubmit,
}) => {
  const tabs: SearchTab[] = ["Vuelos", "Hoteles", "Alquiler de coche"];
  const [tabActive, setTabActive] = useState<SearchTab>(currentTab);

  // Override setTabActive, if tabActive is "Hoteles" we will navigate to /redirect?url=partner.com
  const handleTabChange = (tab: SearchTab) => {
    if (tab === "Hoteles") {
      window.location.href = "/redirect/https%3A%2F%2Fwww.booking.com%2F%3Faid%3Dyoavio";
    } else if (tab === "Alquiler de coche") {
      window.location.href = "/redirect/https%3A%2F%2Fwww.rentalcars.com%2F%3FaffiliateCode%3Dyoavio";
    }
    setTabActive(tab);
  }

  const renderTab = () => {
    return (
      <ul className="ml-2 sm:ml-6 md:ml-12 flex space-x-5 sm:space-x-8 lg:space-x-11 overflow-x-auto hiddenScrollbar">
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => handleTabChange(tab)}
              className={`flex-shrink-0 flex items-center cursor-pointer text-sm lg:text-base font-medium ${
                active
                  ? ""
                  : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"
              } `}
              key={tab}
            >
              {active && (
                <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
              )}
              <span>{tab}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    const isArchivePage = !!currentPage && !!currentTab;
    switch (tabActive) {
      case "Vuelos":
        return <FlightSearchForm haveDefaultValue={isArchivePage} handleSubmit={handleSubmit}/>;
      default:
        return null;
    }
  };

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
      data-nc-id="HeroSearchForm"
    >
      {renderTab()}
      {renderForm()}
    </div>
  );
};

export default HeroSearchForm;
