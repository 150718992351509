import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useParams, Outlet } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import ProtectedRoute from "./ProtectedRoute";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import ConfirmationPage from "containers/CheckOutPage/ConfirmationPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageLogout from "containers/PageLogout/PageLogout";
import PageResetPassword from "containers/PageResetPassword/PageResetPassword";
import PageForgotPassword from "containers/PageForgotPassword/PageForgotPassword";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageHome2 from "containers/PageHome/PageHome2";
import SiteHeader from "containers/SiteHeader";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
//
import HelpPage from "containers/HelpPage/HelpPage";
import MyTripsPage from "containers/MyTripsPage/MyTripsPage";
import MarkdownPage from "containers/MarkdownPage/MarkdownPage";

export const pages: Page[] = [
  { path: "/", exact: true, component: <PageHome/> },
  { path: "/#", exact: true, component: <PageHome/>},
  //
  { path: "/signup", component: <PageSignUp/> }, // It is not going to be used
  { path: "/login", component: <PageLogin/> },
  { path: "/logout", component: <PageLogout/>, isPrivate: true },
  { path: "/forgot-password", component: <PageForgotPassword/> },
  { path: "/reset-password/:token", component: <PageResetPassword/> },
  //
  { path: "/checkout/:flightId/:numberOfPassengers", component: <CheckOutPage/> },
  { path: "/confirmation/:bookingId", component: <ConfirmationPage/> },
  //
  { path: "/account", component: <AccountPage/>, isPrivate: true },
  { path: "/account-password", component: <AccountPass/>, isPrivate: true },
  { path: "/account-billing", component: <AccountBilling/>, isPrivate: true },
  //
  { path: "/blog", component: <BlogPage/> },
  { path: "/blog-single", component: <BlogSingle/> },
  //
  { path: "/contact", component: <PageContact/> },
  { path: "/about", component: <PageAbout/> },
  { path: "/my-trips", component: <MyTripsPage/>, isPrivate: true },
  { path: "/help", component: <HelpPage/> },
  { path: "/terms-and-conditions", component: <MarkdownPage title="Terminos y condiciones" description="Condiciones" filename="terms_and_conditions" /> },
  { path: "/privacy-policy", component: <MarkdownPage title="Política de privacidad" description="Condiciones" filename="privacy_policy" /> },
  { path: "/legal-notice", component: <MarkdownPage title="Aviso legal" description="Condiciones" filename="legal_notice" /> },
  { path: "/flights", component: <ListingFlightsPage/> },
  { path: "/example", component: <MarkdownPage title="Example" description="Test" filename="example" /> },
];

const Redirect = ({}) => {
   let { url } = useParams();
   useEffect(() => {
     window.location.href = url || '';
     console.log(url);
   }, [url]);

   return (<></>);
};

const PageLayout = () => (
  <>
    <SiteHeader />
    <Outlet />
    <Footer />
  </>
);

const MyRoutes = () => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Routes>
        <Route element={<PageLayout />}>
          {pages.map(({ component, path, isPrivate }) => {
            if (isPrivate) {
              return (
                <Route
                  key={String(path)}
                  path={path}
                  element={
                    <ProtectedRoute>
                      {component}
                    </ProtectedRoute>
                  }
                />
              );
            } else {
              return (
                <Route
                  key={String(path)}
                  element={component}
                  path={path}
                />
              )
            }
          })}
          <Route path="*" element={<Page404/>} />
        </Route>
        <Route path="/redirect/:url" element={<Redirect/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default MyRoutes;
