import React from "react";
import { Route, Navigate } from "react-router-dom";
import * as AuthService from "services/AuthService";

const ProtectedRoute = ({ children }) => {
  return AuthService.isAuthenticated() === true ? children : <Navigate
    to={{
      pathname: "/login",
    }}
  />
};

export default ProtectedRoute;

