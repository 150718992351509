import React, { FC, ReactNode, useEffect } from "react";
import imagePng from "images/hero-right2.png";
import ButtonCircle from "shared/Button/ButtonCircle";

import HeroSearchForm, {
  SearchTab,
} from "components/HeroSearchForm/HeroSearchForm";

// List of countries with their respective capitals and demographics
const CountriesAndFacts = [
  {
    name: "Afghanistan",
    capital: "Kabul",
    population: "29.2",
    area: "64,170",
    density: "0.9",
    currency: "Afghani",
    language: "Pashto, Dari...",
    currencySymbol: "؋"
  }, {
    name: "Spain",
    capital: "Madrid",
    population: "46.7",
    area: "505,990",
    density: "1.0",
    currency: "Euro",
    language: "Spanish",
    currencySymbol: "€"
  }, {
    name: "United Kingdom",
    capital: "London",
    population: "66",
    area: "244,820",
    density: "1.0",
    currency: "Pound",
    language: "English",
    currencySymbol: "£"
  }, {
    name: "Italy",
    capital: "Rome",
    population: "60.7",
    area: "301,340",
    density: "1.0",
    currency: "Euro",
    language: "Italian",
    currencySymbol: "€"
  }, {
    name: "France",
    capital: "Paris",
    population: "66.6",
    area: "640,770",
    density: "1.0",
    currency: "Euro",
    language: "French",
    currencySymbol: "€"
  }, {
    name: "Germany",
    capital: "Berlin",
    population: "80",
    area: "357,021",
    density: "1.0",
    currency: "Euro",
    language: "German",
    currencySymbol: "€"
  },{
    name: "Turkey",
    capital: "Ankara",
    population: "77.6",
    area: "7,842",
    density: "1.0",
    currency: "Turkish Lira",
    language: "Turkish",
    currencySymbol: "₺"
  }, {
    name: "Canada",
    capital: "Ottawa",
    population: "35.3",
    area: "9,984",
    density: "1.0",
    currency: "Canadian Dollar",
    language: "English",
    currencySymbol: "$"
  }
];


export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage: "Vuelos" | "Hoteles" | "Alquiler de coche";
  currentTab: SearchTab;
  rightImage?: string;
  handleSubmit?: () => void;
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  className = "",
  listingType,
  currentPage,
  currentTab,
  rightImage = imagePng,
  handleSubmit
}) => {
  const [country, setCountry] = React.useState({
    name: "",
    capital: "",
    population: "",
    area: "",
    density: "",
    currency: "",
    currencySymbol: "",
    language: ""
  });

  useEffect(() => {
    // Select a random element from CountriesAndFacts, this only will happen once so we will use useEffect to avoid it
    setCountry(CountriesAndFacts[Math.floor(Math.random() * CountriesAndFacts.length)]);
    // Each 5 seconds we will select a new random element from CountriesAndFacts
    const interval = setInterval(() => {
      // Make country disappear after 5 seconds with a fadeout animation, using css transitions
      setCountry(CountriesAndFacts[Math.floor(Math.random() * CountriesAndFacts.length)]);
    }, 5000);

    // returned function will be called on component unmount
    return () => clearInterval(interval);
  }, []);

  return (country &&
    <div
      className={`nc-SectionHeroArchivePage flex flex-col relative ${className}`}
      data-nc-id="SectionHeroArchivePage"
    >
      <div className="flex flex-col lg:flex-row lg:items-center hidden md:inline-flex">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-6 lg:space-y-10 pb-14 lg:pb-64 xl:pb-80 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
            {country.capital}, {country.name}
          </h2>
          <div className="flex items-center text-base lg:text-lg text-neutral-500 dark:text-neutral-400">
            <i className="text-2xl las la-flag" />
            <span className="ml-2.5">{country.language}</span>
            <span className="mx-5"></span>
            <i className="text-2xl las la-user"></i>
            <span className="ml-2.5">{country.population}M</span>
            {/* Size (area) */}
            <span className="mx-5"></span>
            <i className="text-2xl las la-map-marked-alt"></i>
            <span className="ml-2.5">{country.area} km<sup>2</sup></span>
            {/* Currency symbol */}
            <span className="mx-5"></span>

            <span className="ml-2.5"><ButtonCircle className="cursor-default">{country.currencySymbol}</ButtonCircle></span>
          </div>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={rightImage} alt="hero" />
        </div>
      </div>

      <div className="flow-root w-full">
        <div className="z-10 lg:-mt-40 xl:-mt-56 w-full">
          <HeroSearchForm currentPage={currentPage} currentTab={currentTab} handleSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default SectionHeroArchivePage;
