import Label from "components/Label/Label";
import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import * as CookieService from "services/CookieService";
import { Alert } from "shared/Alert/Alert";
import Config from "config.json";
import { useNavigate } from "react-router-dom";
import * as AuthService from "services/AuthService";

const AccountPass = () => {
  const [profileData, setProfileData] = React.useState<any>();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [successMessage, setSuccessMessage] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData(event.currentTarget);
    const data = {};

    formData.forEach((value, key) => {
      data[key] = value;
    });
    fetch(Config.SERVER_URL + "/change-password", {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRFTOKEN": CookieService.get("csrftoken")
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        if (response.status === 200) {
          return response.json().then(data => {
            setSuccessMessage("Password changed successfully");
            setErrorMessage(undefined);
            // After 2 seconds, logout and redirect to login page
            setTimeout(() => {
              AuthService.logout();
              navigate("/login");
            }, 2000);
          });
        } else {
          response.json().then(data => {
            const firstError = data[Object.keys(data)[0]];
            throw new Error(firstError[Object.keys(firstError)[0]]);
          }).catch(error => {
            setErrorMessage(error.message);
            setSuccessMessage(undefined);
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div>
      <CommonLayout setProfileData={setProfileData}>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Update your password</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <form onSubmit={handleSubmit}>
            <div className="max-w-xl space-y-6">
              {errorMessage && <Alert type="error">{errorMessage}</Alert>}
              {successMessage && <Alert type="success">{successMessage}</Alert>}
              <div>
                <Label>Current password</Label>
                <Input required name="oldPassword" type="password" className="mt-1.5" />
              </div>
              <div>
                <Label>New password</Label>
                <Input required name="password" type="password" className="mt-1.5" />
              </div>
              <div>
                <Label>Confirm password</Label>
                <Input required name="password2" type="password" className="mt-1.5" />
              </div>
              <div className="pt-2">
                <ButtonPrimary disabled={isLoading}>Update password</ButtonPrimary>
              </div>
            </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
