// Component showing 3 dots with an animation
const Loading = () => {
  return (
    <div className="py-40">
      <div className="flex items-center justify-center space-x-2 animate-pulse">
        <div className="w-8 h-8 bg-primary-400 rounded-full"></div>
        <div className="w-8 h-8 bg-primary-300 rounded-full"></div>
        <div className="w-8 h-8 bg-primary-200 rounded-full"></div>
      </div>
    </div>
  );
}

export default Loading;