import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import Alert from "shared/Alert/Alert";
import Loading from "shared/Loading/Loading";
import { TaxonomyType } from "data/types";
import React, { FC } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import Config from "config.json";

export interface ListingFlightsPageProps {
  className?: string;
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "Disfruta de la belleza de Brasil",
    taxonomy: "category",
    count: 172,
    thumbnail:
      "https://images.pexels.com/photos/1118877/pexels-photo-1118877.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    listingType: "flight",
  },
  {
    id: "2",
    href: "#",
    name: "Disfruta de la belleza de París",
    taxonomy: "category",
    count: 28,
    thumbnail:
      "https://images.pexels.com/photos/2412609/pexels-photo-2412609.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    listingType: "flight",
  },
  {
    id: "3",
    href: "#",
    name: "Disfruta de la belleza de Bangkok",
    taxonomy: "category",
    count: 36,
    thumbnail:
      "https://images.pexels.com/photos/732895/pexels-photo-732895.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    listingType: "flight",
  },
  {
    id: "5",
    href: "#",
    name: "Disfruta de la belleza de Singapur",
    taxonomy: "category",
    count: 18,
    thumbnail:
      "https://images.pexels.com/photos/3152124/pexels-photo-3152124.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    listingType: "flight",
  },
  {
    id: "4",
    href: "#",
    name: "Disfruta de la belleza de Seoul",
    taxonomy: "category",
    count: 88,
    thumbnail:
      "https://images.pexels.com/photos/373290/pexels-photo-373290.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    listingType: "flight",
  },
];

const ListingFlightsPage: FC<ListingFlightsPageProps> = ({
  className = "",
}) => {
  const [loading, setLoading] = React.useState(false);
  const gridRef = React.useRef<HTMLDivElement>(null);

  // Results is a list of objects
  const [results, setResults] = React.useState([]);
  const [error, setError] = React.useState<string | null>(null);
  const handleSubmit = () => {
    setLoading(true);
    setResults([]);
    setError(null);
    if (gridRef && gridRef.current) {
      // @ts-ignore: Object is possibly 'null'.
      gridRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
    // Get flights from api and set results to state.
    fetch(Config.SERVER_URL + '/api/flight', {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Something went wrong");
      }
    }).then(results => {
      if (results.length) {
        setResults(results);
      } else {
        setError("Lo siento, no hay vuelos disponibles para los criterios elegidos.");
      }
    }).catch(error => { console.log(error);
    }).finally(() => { setLoading(false); });
  };

  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>Yoavio || Flights</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage="Vuelos"
          currentTab="Vuelos"
          listingType={
            <>
              <i className="text-2xl las la-plane-departure"></i>
              <span className="ml-2.5">1599 flights</span>
            </>
          }
          className="pt-10 pb-24 lg:pb-32 lg:pt-16"
          handleSubmit={handleSubmit}
        />
        <div ref={gridRef} className="-top-16 md:-top-60 relative"></div>
        { loading &&
          <Loading />
        }
        { (results.length > 0) && <SectionGridFilterCard className="pb-24 lg:pb-32" results={results}/> }
        { (error) &&
          <div className="pb-8 -top-12 relative">
            <Alert type="warning">
              {error}
            </Alert>
          </div>
        }
        {/* SECTION 1 */}
        <SectionSliderNewCategories
          heading="Explora nuestros destinos ✈"
          subHeading="Descubre miles de ciudades por todo el mundo"
          categoryCardType="card4"
          itemPerRow={4}
          categories={DEMO_CATS}
          uniqueClassName="ListingFlightsPage-section1"
        />

        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div>
    </div>
  );
};

export default ListingFlightsPage;
