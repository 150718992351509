import React from "react";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import CommonLayout from "./CommonLayout";
import Alert from "shared/Alert/Alert";
import { PencilAltIcon } from "@heroicons/react/outline";
import Loading from "shared/Loading/Loading";
import BookingDetails from "components/BookingDetails/BookingDetails";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Config from "config.json";
import * as CookieService from "services/CookieService";


export interface ConfirmationPageProps {
  className?: string;
}

const ConfirmationPage: FC<ConfirmationPageProps> = ({ className }) => {
  const { bookingId } = useParams();
  const [flightData, setFlightData] = React.useState({});
  const [numberOfPassengers, setNumberOfPassengers] = React.useState(0);
  const [bookingData, setBookingData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(Config.SERVER_URL + "/api/booking/" + bookingId,
       {
          credentials: "include",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "X-CSRFTOKEN": CookieService.get("csrftoken")
          },
        }
      );

      const data = await response.json();
      setFlightData(data.flight);
      setBookingData(data);
      setNumberOfPassengers(data.numberOfSeats);
      setIsLoading(false);
    }
    fetchData();
  }, []);

  return (
    isLoading ? <Loading /> :
    <CommonLayout className={className}>
      <h2 className="text-3xl lg:text-4xl font-semibold">
        ¡Reserva realizada!
      </h2>
      <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
      <div className="mt-4">
        <Alert type="success">
          ✅ Tu pago se ha realizado con éxito. Recibirás un correo con los detalles de tu reserva.
        </Alert>
      </div>
      <h3 className="text-2xl font-semibold">Detalles de la reserva</h3>
      <BookingDetails numberOfPassengers={numberOfPassengers} flightData={flightData} />
      <div className="mt-4">
        <Alert type="info">
          💬 ¿Tiene alguna duda? Póngase en contacto con nosotros a través de nuestro chat o por teléfono.
        </Alert>
      </div>
      <SectionSliderNewCategories
        className="pt-10"
        heading="Do you need a place to stay?"
        subHeading="Explore stays near your destination"
        categoryCardType="card5"
        itemPerRow={4}
        uniqueClassName="PageHome_s3"
      />
      <div className="pt-4">
        <ButtonPrimary>Do you need a car?</ButtonPrimary>
      </div>
    </CommonLayout>
  );
}

export default ConfirmationPage;