import React, { FC } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/SVG-subcribe2.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Alert from "shared/Alert/Alert";

import Config from "config.json";

export interface SectionSubscribe2Props {
  className?: string;
}

// use the render prop and your custom form
const CustomMailchimpSubscribe = (props) => {
   const { status, message, onValidated } = props;
   const handleSubmit = event => {
      event.preventDefault();
      onValidated({
        'EMAIL': event.target.email.value
      });
   }

   return (
      <div className="md:max-w-sm mt-10">
        <form className="relative" onSubmit={handleSubmit}>
          <Input
            required
            aria-required
            placeholder="Escribe tu e-mail"
            type="email"

            name="email"
            disabled={status === "sending"}
          />
          <ButtonCircle
            disabled={status === "sending"}
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-1"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
        {status === "error" && <Alert type="error" className="pt-5">{ message }</Alert>}
        {status === "success" && <Alert type="success" className="pt-5">¡Genial! Ahora solo falta un paso, debes confirmar tu correo electrónico.</Alert>}
      </div>
   );
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Únete a nuestro club 🎉</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Descubre los destinos de moda más increíbles en nuestra newsletter através de nuestros bloggers, recibe descuentos y mucho más.
        </span>
        <ul className="space-y-4 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">Recibe descuentos</span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="red" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">Recibe nuestro magazine mensual</span>
          </li>
        </ul>
        <MailchimpSubscribe
          url={Config.MAILCHIMP_SUBSCRIBE_URL}
          render={({ subscribe, status, message }) => (
            <div>
              <CustomMailchimpSubscribe
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
              />
            </div>
          )}
        />
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionSubscribe2;
