import React, { FC, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Alert } from "shared/Alert/Alert";
import Config from "config.json";
import * as AuthService from "services/AuthService";

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = (props) => {
  const form = useRef(null);
  const [className, setClassName]: any = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event: any) => {
    // @ts-ignore: Object is possibly 'null'.
    event.preventDefault();
    fetch(Config.SERVER_URL + '/login', {
      method: 'POST',
      body: JSON.stringify({ 'username': event.target.username.value, 'password': event.target.password.value }),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    })
      .then(res => {
        if (res.status === 202) {
          AuthService.login({ 'username': event.target.username.value });
          navigate('/');
        } else {
          setErrorMessage('Invalid username or password');
          // @ts-ignore: Object is possibly 'null'.
          form.current.reset();
        }
      })
      .catch(err => {
        setErrorMessage('Invalid username or password');
        // @ts-ignore: Object is possibly 'null'.
        form.current.reset();
      })
  }

  return (
    <div className={`nc-PageLogin ${props.className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Yoavio</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <form ref={form} className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            {errorMessage && <Alert type="error">{errorMessage}</Alert>}
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Correo electrónico
              </span>
              <Input
                // type="email"
                placeholder="example@example.com"
                className="mt-1"
                name="username"
                required
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Contraseña
                <Link to="/forgot-password" className="text-sm">
                  Recuperar contraseña
                </Link>
              </span>
              <Input name="password" type="password" className="mt-1" required />
            </label>
            <ButtonPrimary type="submit">Continuar</ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
