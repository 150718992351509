import React, { FC, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Alert } from "shared/Alert/Alert";
import Config from "config.json";

export interface PageResetPasswordProps {
  className?: string;
}

const PageResetPassword: FC<PageResetPasswordProps> = (props) => {
  const { token } = useParams();
  const form = useRef(null);
  const [className, setClassName]: any = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event: any) => {
    // @ts-ignore: Object is possibly 'null'.
    event.preventDefault();
    // Reset error and success messages
    setErrorMessage('');
    setSuccessMessage('');

    // Validate if password and password2 are the same
    if (event.target.password.value !== event.target.password2.value) {
      setErrorMessage('Passwords do not match');
      return;
    }
    // Set loading state
    setIsLoading(true);
    fetch(Config.SERVER_URL + '/reset-password-confirm', {
      method: 'POST',
      body: JSON.stringify({ 'resetPasswordToken': token, 'password': event.target.password.value }),
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      if (res.status === 201) {
        setSuccessMessage('Password successfully changed. You can now login again. You will be redirected to the login page in 5 seconds.');
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      } else {
        if (res.status === 404) {
          setErrorMessage('Invalid token. Please try again.');
        } else {
          res.json().then(data => {
            const firstError = data[Object.keys(data)[0]];
            setErrorMessage(firstError[Object.keys(firstError)[0]]);
            setSuccessMessage('');
            // @ts-ignore: Object is possibly 'null'.
            form.current.reset();
          });
        }
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <div className={`nc-PageResetPassword ${props.className}`} data-nc-id="PageResetPassword">
      <Helmet>
        <title>Reset Password || Yoavio</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Resetear contraseña
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <form ref={form} className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            {errorMessage && <Alert type="error">{errorMessage}</Alert>}
            {successMessage && <Alert type="success">{successMessage}</Alert>}
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                New password
              </span>
              <Input disabled={isLoading} required name="password" type="password" className="mt-1" />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Confirm password
              </span>
              <Input disabled={isLoading} required name="password2" type="password" className="mt-1" />
            </label>
            <ButtonPrimary disabled={isLoading} type="submit">Continue</ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageResetPassword;
