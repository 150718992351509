import Select from "shared/Select/Select";
import React, { useState } from 'react';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Alert from "shared/Alert/Alert";
import _uniqueId from 'lodash/uniqueId';

const PassengerForm = ( props ) => {
  const { onSubmit, passenger, key } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [ data, setData ] = useState(passenger ? passenger : {
      firstName: '',
      lastName: '',
      gender: 'f',
      birthDate: '',
      id: ''
  });

  const onChange = event => {
    const { name, value } = event.target;
    setData(prevState => ({
      ...prevState,  // shallow copy all previous state
      [name]: value, // update specific key/value
    }));
  }

  const clearForm = () => {
    setData({
      firstName: '',
      lastName: '',
      gender: 'f',
      birthDate: '',
      id: data.id
    });
    setErrorMessage('');
  }

  const handleSubmit = event => {
    event.preventDefault();
    // We validate if birthDate is not empty and if it is not in the future
    if (data.birthDate === '') {
      setErrorMessage('Please enter a birth date');
      return;
    }
    const birthDate = new Date(data.birthDate);
    const now = new Date();
    if (birthDate > now) {
      setErrorMessage('Birth date cannot be in the future');
      return;
    }

    // We validate if birthDate is less than 120 years ago
    const age = now.getFullYear() - birthDate.getFullYear();
    if (age > 120) {
      setErrorMessage('Birth date is not correct');
      return;
    }

    // We generate an unique id for this passenger, only when it is new
    if (!passenger) {
      data.id = _uniqueId('passenger-');
    }
    onSubmit(data);
    clearForm();
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-5">
        {errorMessage && <Alert type="error">{errorMessage}</Alert>}
        <div className="space-y-1">
          <Label>First name</Label>
          <Input required placeholder="Jonh" id="firstName" name="firstName" value={data.firstName} onChange={onChange}/>
        </div>
        <div className="space-y-1">
          <Label>Last name</Label>
          <Input required placeholder="Doe" id="lastName" name="lastName" value={data.lastName} onChange={onChange}/>
        </div>
        <div className="space-y-1">
          <Label>Birth date</Label>
          <Input required placeholder="YYYY-MM-DD" id="birthDate" name="birthDate" type="date" value={data.birthDate} onChange={onChange}/>
        </div>
        <div className="space-y-1">
          <Label>Gender</Label>
          <Select name="gender" onChange={onChange} defaultValue={data.gender ? data.gender : 'f'}>
            <option value="f">Female</option>
            <option value="m">Male</option>
          </Select>
        </div>
        <div className="pt-4">
          <ButtonPrimary>Save passenger</ButtonPrimary>
        </div>
      </div>
    </form>
  );
}

export default PassengerForm;