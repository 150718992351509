import React from "react";
import { FC, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Config from "config.json";
import * as CookieService from "services/CookieService";

export interface CommonLayoutProps {
  children: React.ReactNode;
  setProfileData: (profileData: any) => void;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children, setProfileData }) => {
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    setIsLoading(true);
    // fetch profile data from API and store it in variable profileData
    fetch(Config.SERVER_URL + "/profile", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRFTOKEN": CookieService.get("csrftoken")
      }
    })
      .then(res => res.json())
      .then(profileData => {
        // set profileData to state
        setProfileData(profileData);
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  return (
      <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900">
      <div className="border-b border-neutral-200 dark:border-neutral-700 pt-12 bg-white dark:bg-neutral-800">
        <div className="container">
          <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
            <NavLink
              className={(navData) => `block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0 ${navData.isActive ? "!border-primary-500" : ""}` }
              to="/account"
            >
              Account
            </NavLink>
            <NavLink
              className={(navData) => `block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0 ${navData.isActive ? "!border-primary-500" : ""}` }
              to="/account-password"
            >
              Change password
            </NavLink>
            <NavLink
              className={(navData) => `block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0 ${navData.isActive ? "!border-primary-500" : ""}` }
              to="/account-billing"
            >
              Billing
            </NavLink>
          </div>
        </div>
      </div>
      {!isLoading && (<div className="container pt-14 sm:pt-20 pb-24 lg:pb-32">{children}</div>)}

    </div>
  );
};

export default CommonLayout;
