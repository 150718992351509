import React from "react";
import { FC, useEffect } from "react";
import CommonLayout from "containers/CheckOutPage/CommonLayout";
import Alert from "shared/Alert/Alert";
import { PencilAltIcon } from "@heroicons/react/outline";
import Loading from "shared/Loading/Loading";
import BookingDetails from "components/BookingDetails/BookingDetails";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Pagination from "shared/Pagination/Pagination";
import Config from "config.json";
import * as CookieService from "services/CookieService";


export interface MyTripsPageProps {
  className?: string;
}

const MyTripsPage: FC<MyTripsPageProps> = ({ className }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [bookings, setBookings] = React.useState<any[]>([]);

  useEffect(() => {
    setIsLoading(true);
    // Fetch bookings list from /api/booking
    fetch(Config.SERVER_URL + "/api/booking/", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRFTOKEN": CookieService.get("csrftoken")
      }
    })
      .then(res => res.json())
      .then(res => {
        setBookings(res);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        console.log(err);
      }
      );
  }, []);

  return (
    isLoading ? <Loading /> :
    <CommonLayout className={className}>
      <h2 className="text-3xl lg:text-4xl font-semibold">
        Mis viajes
      </h2>
      <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
      {/* Iterate through bookings list and display each booking */}
      {/* TODO: Add pagination and call to action */}
      {(bookings.length > 0) ? (
        bookings.map((booking, index) => {
          return (
            <>
              <h3 className="text-2xl font-semibold mt-4">{booking.flight.flightNumber}</h3>
              <BookingDetails numberOfPassengers={booking.numberOfPassengers} flightData={booking.flight} />
            </>
          )
        })) : (
          <div className="flex-shrink-0">
            <Alert type="warning">Todavía no has reservado ningún vuelo</Alert>
            <ButtonPrimary href="/flights" className="mt-8">Reserva tu vuelo</ButtonPrimary>
          </div>
          )
        }
      {(bookings.length > 0) && <Pagination className="mt-4" />}
    </CommonLayout>
  );
}

export default MyTripsPage;